import React from "react";
import styles from "./richtext.module.css";

const RichText = ({ html, className, style }) => (
  <div
    className={`${styles.rt} ${className}`}
    dangerouslySetInnerHTML={{
      __html: html
    }}
    style={style}
  />
);

export default RichText;
