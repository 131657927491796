import React, { useState } from "react";
import Img from "gatsby-image";
import FsLightbox from "fslightbox-react";

const ImageGallery = ({ items }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  const slidesContent = items.map(item => (
    <div style={{ width: "85vw", height: "90vh" }}>
      <div className="h-full w-full">
        {item.image_captions && (
          <p
            className="font-sans absolute w-full pl-5 text-white"
            style={{ marginTop: "-27px" }}
          >
            {item.image_captions.text}
          </p>
        )}
        <Img
          fluid={item.gallery_image.fluid}
          className="h-full max-w-full"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
    </div>
  ));

  return (
    <>
      <h2 className="text-center text-3xl md:text-5xl my-5 md:my-10">
        Gallery
      </h2>
      <div className="flex flex-wrap max-w-6xl mx-auto">
        <div className="flex flex-wrap justify-center">
          {items.map(({ gallery_image, image_captions }, index) => (
            <div
              key={index}
              onClick={() =>
                setLightboxController({
                  toggler: !lightboxController.toggler,
                  slide: index + 1
                })
              }
              className="cursor-pointer w-1/2 md:w-64 p-3"
            >
              <Img
                fluid={{ ...gallery_image.fluid, aspectRatio: 1 }}
                className="w-full"
              />
              {image_captions && (
                <p className="leading-tight text-sm py-2">
                  {image_captions.text}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={slidesContent}
        slide={lightboxController.slide}
      />
    </>
  );
};

export default ImageGallery;
