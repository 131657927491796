import React, { useState } from "react";
import Img from "gatsby-image";
import FsLightbox from "fslightbox-react";
import classnames from "classnames";

import RichText from "../richtext";

const PhotoEssaySlice = ({ items }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  const slidesContent = items.map(item => (
    <div style={{ width: "85vw", height: "90vh" }}>
      <div className="h-full w-full">
        {item.image_text && (
          <RichText
            className="font-sans absolute w-full pl-5 text-white"
            style={{ marginTop: "-27px" }}
            html={item.image_text.html}
          />
        )}
        <Img
          fluid={item.image.fluid}
          className="h-full max-w-full"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
    </div>
  ));

  return (
    <>
      <div className="flex flex-col justify-center">
        {items.map(
          ({ image, image_date, image_display, image_text }, index) => {
            const isPortrait = image.dimensions.height > image.dimensions.width;
            return (
              <div
                key={index}
                onClick={() =>
                  setLightboxController({
                    toggler: !lightboxController.toggler,
                    slide: index + 1
                  })
                }
                className="cursor-pointer mb-8"
              >
                <Img
                  fluid={{ ...image.fluid }}
                  imgStyle={{ objectFit: "contain" }}
                  className={classnames({
                    "max-w-4xl mx-auto":
                      image_display === "content" && !isPortrait,
                    "max-h-screen": isPortrait,
                    "w-full": image_display === "full" && !isPortrait
                  })}
                />
                {image_text && (
                  <RichText
                    html={image_text.html}
                    className={"leading-tight text-center py-2 px-4"}
                  />
                )}
              </div>
            );
          }
        )}
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={slidesContent}
        slide={lightboxController.slide}
      />
    </>
  );
};

export default PhotoEssaySlice;
