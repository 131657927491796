import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import * as toGeoJSON from "@tmcw/togeojson";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

import { hasWindow } from "../utils/dom";

import { DOMParser } from "xmldom";

const Map = ({ sourceUrl }) => {
  if (!hasWindow) {
    return null;
  }

  const mapNode = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    console.log("in effect");
    mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN;

    axios.get(sourceUrl).then(function(result) {
      const gpx = new DOMParser().parseFromString(result.data);
      const geoJSON = toGeoJSON.gpx(gpx);
      const mapSource = {
        type: "geojson",
        data: geoJSON.features[0]
      };

      const map = new mapboxgl.Map({
        container: mapNode.current,
        style: `mapbox://styles/mapbox/outdoors-v11`,
        center: [-71.54, 44.13],
        zoom: 8.5
      });

      mapRef.current = map;
      window.map = map; // for easier debugging and querying via console

      map.on("load", () => {
        console.log("map onload");
        console.log(mapSource);
        map.addSource("hike", mapSource);

        map.addLayer({
          id: "route",
          type: "line",
          source: "hike",
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            "line-color": "#ff0000",
            "line-width": 3
          }
        });

        const coordinates = mapSource.data.geometry.coordinates;
        var bounds = coordinates.reduce((bounds, coord) => {
          return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

        map.fitBounds(bounds, {
          padding: 20
        });
      });

      // hook up map events here, such as click, mouseenter, mouseleave
      // e.g., map.on('click', (e) => {})
      // when this component is destroyed, remove the map

      return () => {
        map.remove();
      };
    });
  }, [sourceUrl]);

  return (
    <>
      <div ref={mapNode} className="w-full h-screen" />
      <a
        className="ml-auto text-sm px-4 underline hover:no-underline"
        href={sourceUrl}
        download
      >
        Download .gpx data
      </a>
    </>
  );
};

export default Map;
