import React from "react";
import RichText from "../richtext";
import BlogSidebarElem from "../blog-sidebar-elem";
import PageTitle from "../pagetitle";

const SubBlogSlice = ({
  section_title,
  section_subtitle,
  section_date,
  section_body,
  items
}) => (
  <section className="max-w-6xl mx-auto px-4">
    <div className="mb-8">
      <PageTitle text={section_title} hValue="h2" />
      <PageTitle text={section_subtitle} hValue="h4" />
    </div>
    <div className="flex flex-col md:flex-row">
      <div className="max-w-3xl mb-10">
        <RichText html={section_body.html} />
      </div>
      <div className="md:ml-8 md:w-1/3 lg:w-1/4">
        {items.map(
          ({ section_sidebar_body, section_sidebar_headline }, index) => (
            <BlogSidebarElem
              title={section_sidebar_headline}
              body={section_sidebar_body}
              key={index}
            />
          )
        )}
      </div>
    </div>
  </section>
);

export default SubBlogSlice;
