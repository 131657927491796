import React from "react";
import RichText from "./richtext";

const BlogSidebarElem = ({ title, body }) => (
  <div className="mb-4">
    <h5 className="text-sm">{title}</h5>
    <RichText html={body.html ? body.html : `<p>${body}</p>`} />
  </div>
);

export default BlogSidebarElem;
