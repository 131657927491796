import React from "react";
import PropTypes from "prop-types";

import ImageGallery from "./image-gallery";
import SubBlogSlice from "./sub-blog";
import PhotoEssaySlice from "./photo-essay";

const Slice = ({ slice_type, primary, items }) => (
  <>
    {slice_type === "image_gallery" && <ImageGallery items={items} />}
    {slice_type === "sub_blog" && <SubBlogSlice {...primary} items={items} />}
    {slice_type === "photo_essay" && (
      <PhotoEssaySlice {...primary} items={items} />
    )}
  </>
);

export default Slice;

Slice.propTypes = {
  slice_type: PropTypes.string.isRequired,
  primary: PropTypes.object,
  items: PropTypes.array
};
