import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Moment from "react-moment";

import Layout from "../components/layout";
import Map from "../components/map";
import Pagination from "../components/pagination";
import Slice from "../components/slice/index";
import PageTitle from "../components/pagetitle";
import RichText from "../components/richtext";
import BlogSidebarElem from "../components/blog-sidebar-elem";
import BlogPostTags from "../components/blog-post-tags";

const BlogPost = ({ data, pageContext }) => {
  const {
    title,
    date,
    post_body,
    subtitle,
    featured_image,
    sidebar_section,
    body,
    gpx,
    tags
  } = data.prismicBlogPost.data;

  const { next, prev } = pageContext;

  let nextProps, prevProps;
  if (next) {
    const nextDateArr = next.data.date.split("-");
    nextProps = {
      url: `/blog/${nextDateArr[0]}/${nextDateArr[1]}/${nextDateArr[2]}/${next.uid}/`,
      image: next.data.image,
      text: next.data.title.text,
      date: next.data.date
    };
  }

  if (prev) {
    const prevDateArr = prev.data.date.split("-");
    prevProps = {
      url: `/blog/${prevDateArr[0]}/${prevDateArr[1]}/${prevDateArr[2]}/${prev.uid}/`,
      image: prev.data.image,
      text: prev.data.title.text,
      date: prev.data.date
    };
  }
  return (
    <Layout>
      <div className="max-w-6xl mx-auto px-4">
        <div className="mb-8 text-center">
          <p>
            <Moment format="MMM DD, YYYY">{date}</Moment>
          </p>
          <PageTitle text={title.text} />
          <h3 className="text-xl md:text-3xl">{subtitle.text}</h3>
          {tags && <BlogPostTags tags={tags} />}
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="max-w-3xl mb-10">
            {featured_image.fluid && (
              <Img
                fluid={featured_image.fluid}
                alt={`Featured Image: ${title.text}`}
                className="mb-10 w-full max-h-screen"
                imgStyle={{ objectFit: "contain" }}
              />
            )}
            <RichText html={post_body.html} />
          </div>
          {sidebar_section.length > 1 && (
            <div className="md:ml-8 md:w-1/3 lg:w-1/4">
              {sidebar_section.map(
                ({ sidebar_body, sidebar_headline }, index) => (
                  <BlogSidebarElem
                    title={sidebar_headline}
                    body={sidebar_body}
                    key={index}
                  />
                )
              )}
            </div>
          )}
        </div>
      </div>
      {gpx.url && <Map sourceUrl={gpx.url} />}
      {body.map((slice, index) => (
        <Slice {...slice} key={index} />
      ))}
      <Pagination next={nextProps} prev={prevProps} />
    </Layout>
  );
};

export const query = graphql`
  query BlogPostPageByUid($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      data {
        date
        title {
          text
        }
        subtitle {
          text
        }
        featured_image {
          fluid(maxWidth: 768) {
            ...GatsbyPrismicImageFluid
          }
        }
        post_body {
          html
        }
        gpx {
          url
        }
        tags {
          tag {
            document {
              ... on PrismicTag {
                uid
                data {
                  name
                }
              }
            }
          }
        }
        sidebar_section {
          sidebar_body {
            html
          }
          sidebar_headline
        }
        body {
          ... on PrismicBlogPostBodyImageGallery {
            slice_type
            items {
              gallery_image {
                fluid(maxWidth: 1440, maxHeight: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              image_captions {
                text
              }
            }
          }
          ... on PrismicBlogPostBodySubBlog {
            primary {
              section_title
              section_subtitle
              section_date
              section_body {
                html
              }
            }
            slice_type
            items {
              section_sidebar_headline
              section_sidebar_body
            }
          }
          ... on PrismicBlogPostBodyPhotoEssay {
            slice_type
            items {
              image {
                fluid(maxWidth: 1440) {
                  ...GatsbyPrismicImageFluid
                }
                dimensions {
                  height
                  width
                }
              }
              image_date
              image_display
              image_text {
                html
              }
            }
          }
        }
      }
    }
  }
`;
export default BlogPost;
